import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SurveyArticles from "../components/SurveyComponents/SurveyArticles"
import Seo from '../components/seo';

export const query = graphql`
  query MyQuery($id: ID!) {
    wpcontent {
      surveyTemplate(id: $id) {
        content
        excerpt
        templateImage
        icon
        id
        title
        uri
        seo {
          title
          metaDesc
        }
      }
      surveyTemplates(where: { notIn: [$id] }, first: 3) {
        nodes {
          icon
          excerpt
          title
          uri
          id
          slug
        }
      }
    }
    allGhostPost(
      filter: { tags: { elemMatch: { name: { eq: "Employee Survey" } } } }
      limit: 3
    ) {
      edges {
        node {
          tags {
            name
          }
          url
          title
          excerpt
          feature_image
        }
      }
    }
  }
`

function surveyPages({ data }) {
  const surveyTempplate = data.wpcontent.surveyTemplate
  const relatedTemplates = data.wpcontent.surveyTemplates.nodes
  const ghostPosts = data.allGhostPost.edges
  const metaDesc = data.wpcontent.surveyTemplate.seo.metaDesc
  const pageTitle = data.wpcontent.surveyTemplate.seo.title
  let uri = data.wpcontent.surveyTemplate?.uri
    
  let baseUrl = "https://www.vantagecircle.com";

  let pageUri = baseUrl + uri
  
  console.log("ghostPosts", ghostPosts)
  return (
    <Layout>
     <Seo title={pageTitle}
              description={metaDesc}
              link={[
            {
              rel: `canonical`,
              href: pageUri,
            },
          ]}
      />

      <div className="survey-reports">
        <section className="bg-purple-100 mx-auto py-10 pb-10 sm:py-20 sm:pb-20 relative">
          <div className="container max-w-7xl sm:text-center md:text-left px-6 xl:grid grid-cols-2 justify-center items-center">
            <div>
              <div className="site-breadcrumb mb-5 text-gray-900 text-center xl:text-left">
                <Link
                  className="hover:underline text-gray-900"
                  to="/survey-templates/"
                >
                  Survey Template
                </Link>
                &nbsp;&gt;&nbsp;
                <Link
                  className="text-gray-200"
                  dangerouslySetInnerHTML={{ __html: surveyTempplate.title }}
                />
              </div>
              <h1
                className="text-3xl md:text-4xl xl:text-7xl text-center xl:text-left lg:pr-3 xl:w-10/12"
                dangerouslySetInnerHTML={{ __html: surveyTempplate.title }}
              />
              <p
                className="my-6 text-lg xl:text-xl xl:w-10/12 text-center xl:text-left text-purple-lightpurple"
                dangerouslySetInnerHTML={{ __html: surveyTempplate.excerpt }}
              />
              <div className="text-center xl:text-left">
                <Link to="/request-demo/">
                  <button className="px-12 py-3 rounded-2xl bg-orange border border-orange text-white text-sm xl:text-base transform  hover:-translate-y-1  duration-500 ease-in-out">
                    Try for free
                  </button>
                </Link>
                <Link to="/survey-templates/">
                  <button className="border px-10 py-3 mt-2 xl:mt-0 rounded-2xl border-orange text-orange text-sm xl:text-base ml-0 xl:ml-2 transform hover:-translate-y-1  duration-500 ease-in-out">
                    See all templates
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex justify-center lg:justify-end">
              <img
                className="rounded-lg shadow-lg mt-10 xl:mt-0"
                src={`${surveyTempplate.templateImage}`}
                alt={surveyTempplate.title}
                width={400}
                height={350}
              />
            </div>
          </div>
        </section>
        <section className="mx-auto py-10 pb-5 sm:py-20 sm:pb-20 relative survey-contents ">
          <div className="container max-w-5xl text-left px-6 flex justify-center items-center">
            <div
              dangerouslySetInnerHTML={{ __html: surveyTempplate.content }}
            />
          </div>
        </section>
        <section className="bg-purple-100">
          <div className="container px-6 lg:px-0 max-w-7xl mx-auto py-10 md:pb-14 md:pt-20 md:-mt-0 flex flex-col justify-center items-center">
            <h1 className="text-3xl md:text-4xl text-center mb-10 md:mb-20">
              Other Related Templates
            </h1>
            <div className="grid md:grid-cols-3 gap-x-8 gap-y-4 w-full">
              {relatedTemplates.map((template, index) => {
                return <SurveyArticles template={template} key={index} />
              })}
            </div>
          </div>
        </section>
        <section>
          <div className="container px-6 lg:px-0 max-w-7xl mx-auto py-10 md:pb-14 md:pt-20 md:-mt-0 flex flex-col justify-center items-center">
            <h1 className="text-3xl md:text-4xl text-center mb-10 md:mb-20">
              Other Resources
            </h1>
            <div className="grid md:grid-cols-3 gap-x-8 gap-y-4 w-full">
              {ghostPosts.map((post, index) => {
                return (
                  <article
                    className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg "
                    key={index}
                  >
                    <div>
                      <img
                        className="w-full rounded-md"
                        src={post.node.feature_image}
                        alt={post.node.title}
                      />
                      <h2 className="box-title font-bold my-3 clamp-2">
                        {post.node.title}
                      </h2>
                      <p className="box-subtitle line-clamp-3 mb-3">
                        {post.node.excerpt}
                      </p>
                      <Link to={`${post.node.url}`}>
                        <button className="border px-4 py-2 rounded-2xl border-orange transform hover:-translate-y-1  duration-500 ease-in-out">
                          <p className="text-orange text-sm m-0">Read now</p>
                        </button>
                      </Link>
                    </div>
                  </article>
                )
              })}
            </div>
            <div className="my-5 md:my-10 text-center">
              <Link
                to="https://www.vantagecircle.com/en/blog/tag/employee-survey/"
                className="text-orange text-base hover:underline "
              >
                View More
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default surveyPages
